<template>
  <div class="container">
    <section class="orders-header">
      <h1 class="titlePage titlePage_textLeft"> {{getUserName}} </h1>
    </section>

    <section>
      <scopped-options :package="package"
                       :options="options"
                       :type="type"
                       :editFromPackage="IsEditFromPackage"
                       :isEdit="IsEdit"
      />
    </section>

    <div class="row" v-if="allOptions">
      <div class="form__row form__row_full form__row_margin form__row_start ml-2">
        <button class="btn_default btn"
                type="submit"
                @click="postPackage">
          {{ this.IsEdit ? 'Save changes' : 'Create package' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import router from "../../router";
  import {mapGetters, mapState, mapMutations} from 'vuex';
  import {defineAsyncComponent} from "vue";

  const warningOutOfSquare = defineAsyncComponent(() => import('../modals/WarningOutOfSquare'));
  const breadcrumbs = defineAsyncComponent(() => import("./../parts/breadcrumbs"));
  const ScoppedOptions = defineAsyncComponent(() => import("./options/ScoppedOptions"));

  export default {
    name: "SelectOptionsToPackage",

    components: {
      breadcrumbs,
      warningOutOfSquare,
      ScoppedOptions,
    },

    props: {
      checkBox: Boolean,
      package: Boolean,
    },

    data() {
      return {
        doneOptions: ['MESH', 'INS', 'INSCOLOUR', 'LOCK', 'HOPH', 'CYL', 'WHL', 'HNG', 'LFTT', 'MFLP', 'PRTS'],
        packageName: '',
        selectedOptions: {},
        prevOption: ''
      }
    },

    methods: {
      ...mapMutations('options', ['setOptionsToOrder', 'setOptionsToPackage', 'setShowAll']),

      actionSet() {
        if (!this.packageName) {
          return this.$store.commit('errorsModal/openModal', {0: ['You must enter name']});
        }

        if (!this.appendPackage) {
          return this.createSet();
        }

        this.$store.commit('sets/set', {k: 'packageName', v: this.packageName});
        this.$store.dispatch('sets/appendPackageToSet');
        this.$store.commit('createdOrder/selectModal', false);
        router.push({name: 'sets'});
      },

      createSet() {
        this.$store.commit('sets/set', {k: 'packageName', v: this.packageName});
        this.$store.dispatch('sets/createSetWithPackage');
        this.$store.commit('createdOrder/selectModal', false);
        router.push({name: 'sets'});
      },

      postPackage() {
        if (!this.IsEdit) {
          this.$store.dispatch('packages/postPackages', this.$store.getters['createdOrder/createdOrder']);
          router.push({name: 'packages'});
        }

        else if (this.IsEdit && this.IsEditFromPackage) {
          this.$store.dispatch('packages/update');
        }

        else if (this.IsEdit && this.IsEditFromSet) {
          this.$store.dispatch('packages/update');
          router.push({name: 'sets'});
        }
        this.$store.commit('options/cleanOptions');
      },

      ckeckAvailableOptions() {
        if (!this.allOptions) {
          this.setOptionsToPackage(this.options);
          this.setShowAll(true);
          return;
        }
      }
    },

    computed: {
      isEditOrderItem() {
        return this.$store.getters['orderItem/get']('isUpdate');
      },

      isSetState() {
        return this.$store.getters['sets/get']('isSetState');
      },

      getUserName() {
        return localStorage.getItem('nameUser');
      },

      getSelectedOptions() {
        return this.selectedOptions;
      },

      ...mapGetters({
        IsEdit: 'packages/IsEdit',
        IsEditFromSet: 'packages/IsEditFromSet',
        IsEditFromPackage: 'packages/IsEditFromPackage',
        user: 'auth/nameUser',
        options: 'options/options',
        optionsList: 'options/optionsList',
        type: 'options/type',
        orderItem: 'createdOrder/createdOrder',
        update: 'types/updates',
      }),

      ...mapState('options', ['allOptions', 'itemId', 'incomplete']),

      disabledFromIncomplete() {
        if (this.incomplete.length > 0) {
          return true;
        }
        else return false;
      },
    },

    created() {
      if (this.checkBox && !this.IsEdit) {
        this.$store.dispatch('options/getOptionsToPackage');
      }
    },

    beforeUpdate() {
      this.ckeckAvailableOptions();
    }
  }
</script>

<style lang="scss" scoped>

  @import '../../assets/scss/utils/vars';

  .option_name {
    text-transform: capitalize;
    text-align: center;
  }

  .form__input {
    border: none;
    border-bottom: 2px solid $navy;
  }
</style>
